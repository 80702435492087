/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This is my second post on this test blog!"), "\n", React.createElement(_components.p, null, "Some interesting things:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "JavaScript"), "\n", React.createElement(_components.li, null, "React"), "\n", React.createElement(_components.li, null, "Gatsby"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
