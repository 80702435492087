import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/blog/second-post/second-post.mdx";
import * as React from "react";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Layout from "../../components/Layout";
function Post({data, children}) {
  const image = getImage(data.mdx.frontmatter.image);
  return React.createElement(Layout, {
    title: data.mdx.frontmatter.title
  }, React.createElement("p", null, "Posted: ", data.mdx.frontmatter.posted), React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.image_alt
  }), children);
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query PostData($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        description
        slug
        image_alt
        posted
      }
      excerpt
    }
  }
`;
